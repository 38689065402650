<template>
    <v-card>
        <v-card-text style="word-break: break-word;">
            <div class="d-flex align-center">
                <div class="black-color text-subtitle-1">
                    Добавить ключ для входа в приложение
                </div>
                <v-btn
                    icon
                    x-large
                    :dark="true"
                    color="success"
                    :loading="createPasskeyLoading"
                    @click="createPasskey"
                >
                    <v-icon>mdi-fingerprint</v-icon>
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapActions} from 'vuex'
import {arrayBufferToBase64, recursiveBase64StrToArrayBuffer} from '@/passkeyFns'

export default {

    props: ['showCreatePasskey'],

    data: () => ({
        createPasskeyLoading: false
    }),

    methods: {

        ...mapActions([
            'request',
            'alert'
        ]),

        async createPasskey() {
            if (!this.showCreatePasskey) return

            this.createPasskeyLoading = true

            let createArgs = await this.request({
                controller: 'PasskeyCreate',
                action: 'getCreateArgs'
            }).catch(() => {
                this.createPasskeyLoading = false
                this.alert({
                    text: 'Что-то пошло не так',
                    color: 'error'
                })
            })
            if (!createArgs) {
                this.createPasskeyLoading = false
                return
            }

            recursiveBase64StrToArrayBuffer(createArgs)
            const credential = await navigator.credentials.create(createArgs)
                .catch(() => {
                })
            if (!credential) {
                this.createPasskeyLoading = false
                return
            }

            let authenticatorAttestationResponse = {
                transports: credential.response.getTransports ? credential.response.getTransports() : null,
                clientDataJSON: credential.response.clientDataJSON ? arrayBufferToBase64(credential.response.clientDataJSON) : null,
                attestationObject: credential.response.attestationObject ? arrayBufferToBase64(credential.response.attestationObject) : null
            }

            await this.request({
                controller: 'PasskeyCreate',
                action: 'processCreate',
                ...authenticatorAttestationResponse
            }).then(() => {
                this.alert({
                    text: 'Ключ доступа добавлен',
                    color: 'success'
                })
            }).catch(() => {
                this.alert({
                    text: 'Что-то пошло не так',
                    color: 'error'
                })
            }).finally(() => {
                this.createPasskeyLoading = false
            })
        }
    }
}
</script>